.search-result {
	margin-bottom: 4em;
}


.search-result__more {
	background-color: $footer-green;
	border-radius: 4px;
	display: inline-block;
	padding: 8px 12px;
	color: white;
	opacity: .9;
	transition: opacity .2s ease-in-out;

	&:hover {
		opacity: 1;
	}
}