.service-info {
	@include grid-column(12);
	margin-bottom: 20px;
}



.service-info__title {
	display: inline-block;
	font-weight: bold;

	i {
		color: $footer-green;
		font-size: 22px;
		vertical-align: middle;
		padding-right: 15px;
	}
}


.service-info__extra {
	font-weight: bold;
}


@media only screen and (min-width: 700px) {
	.service-info {
		@include grid-column(4);
	}
}
