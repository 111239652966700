.supplier-slideshow {
  width: 1000px;
  height: 230px;
  margin: 0 auto;

  .supplier-slide {
    height: 230px;
    width: 230px;
    background-size: cover;
    display: inline-block;
    margin: 0 10px;
  }
}

@media(max-width: 425px){
  .supplier-slideshow{
    width: 400px;
    height: 340px;

    .supplier-slide{
      height: 340px;
      width: 340px;
      margin: 0 30px;
    }
  }
}