.single-col {
  h6 {
    font-size: 13px;
    font-weight: bold;
  }

  .body-image {
    width: 480px;
    height: 360px;
    margin: 0 10px 10px 10px;
  }

  @media (max-width: 1024px) {
    .body-image {
      width: 400px;
      height: 300px;
    }
  }

  @media (max-width: 768px) {
    .body-image {
      width: 300px;
      height: 225px;
    }
  }

  @media (max-width: 425px) {
    .body-image {
      margin: 0;
    }
  }
}
