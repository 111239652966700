.u-text-upper {
	text-transform: uppercase;
}

.u-text-right {
	text-align: right;
}


.u-small-green-header {
	color: #008000;
}


.u-big-green-header {
	color: #008000;
	margin-bottom: 0;
}

.u-float-right {
	float: right;
}