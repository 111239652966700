.global-footer__bar {
  background-color: $footer-green;
  height: 40px;
}

.global-footer {
	padding: 25px 0;
	font-family: $helvetica-n;
	color: #848484;
	font-size: 12px;
}

.global-footer__copy {
	margin-bottom: 0;
	display: inline-block;
}

.global-footer__nav {
	margin-top: 20px;
	a {
		color: $footer-green;
		font-size: 13px;
		line-height: 9px;
		display: inline-block;
		border-right: 1px solid grey;
		padding-right: 5px;

		&:last-child {
			padding-right: 0;
			border-right: 0;
		}
	}
}


@media only screen and (min-width: 545px) {
	.global-footer__nav {
		margin-top: 0;
		float: right;
	}
}

.cross-link {
	background: lighten($heading-colour, 60%);
	margin-top: 80px;

	&__container {
		@extend .container;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	&__logo {
		@include grid-shift(1);
		@include grid-column(2);
	}

	&__text {
		@include grid-shift(1);
		@include grid-column(8);

		h2 a {
			color: $heading-colour;
		}
	}

	@media (max-width: 990px) {
		&__logo {
			@include grid-shift(false);
			@include grid-column(3);
		}

		&__text {
			@include grid-shift(false);
			@include grid-column(9);
		}
	}

	@media (max-width: 600px) {
		&__logo {
			@include grid-column(4);
		}

		&__text {
			@include grid-column(8);
		}
	}

	@media (max-width: 450px) {
		&__logo {
			@include grid-column(12);
			margin-bottom: 10px;
		}

		&__text {
			@include grid-column(12);
		}
	}

}