/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $helvetica;
  color: $base-text-colour;
  font-size: 15px;
  line-height: 1.5;

  > section:last-of-type {
    margin-bottom: 0; // Remove mb from last section on every page and let mt on footer do the spacing
  }
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ul {
  padding-left: 0;
  list-style-position: inside;
}

table {
  border-collapse: collapse;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  margin-bottom: 1.5em;
}

td {
  padding: 5px 7px;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
}

label {
  font-size: 13px;
  display: block;
  margin-bottom: 20px;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 8px;
  line-height: 14px;
  transition: border-color .2s ease-in-out;
  border: 1px solid #ccc;
  font-family: $helvetica;
  font-size: 1em;

  &:hover,
  &:focus {
    outline: none;
    border-color: #DDD;
  }
}

textarea {
  resize: vertical;
  min-height: 80px;
}

input[type="submit"], button {
  display: inline-block;
  width: auto;
  background-color: $footer-green;
  color: white;
  transition: opacity .2s ease-in-out;
  opacity: .9;
  border: 0;
  cursor: pointer;
  padding: 12px 20px;

  &:hover,
  &:focus {
    opacity: 1;
  }
}