.blog-post {
  @include grid-column(12);
  margin-bottom: 20px;
}

.blog-post__img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-post__title {
  margin-bottom: 5px;
  font-weight: 600;
}

.blog-post__meta {
  margin: 16px 0;
  i {
    background-color: $footer-green;
    height: 33px;
    width: 33px;
    line-height: 33px; // height, width and line-height are same so it's centered
    font-size: 17px;
    color: white;
    text-align: center;
    vertical-align: middle;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  span {
    display: inline-block;
    background-color: $heading-colour;
    color: white;
    text-align: center;
    height: 33px;
    vertical-align: middle;
    line-height: 33px;
    padding: 0 10px;
    font-weight: bold;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.blog-post__more {
  color: $footer-green;
  transition: color .2s ease-in-out;

  &:hover {
    color: #888;
  }
}

@media only screen and (min-width: 690px) {
  .blog-post {
    @include grid-column(4);
  }

  .blog-post__img {
    height: 120px;
  }
}

@media only screen and (min-width: 850px) {
  .blog-post__img {
    height: 150px;
  }
}