.breadcrumb-bar {
	padding: 20px 0 0;
	margin-bottom: 40px;
}

.breadcrumb-bar__border {
	margin-top: 20px;
	@include grid-column(12);
	height: 1px;
	background-color: lightgrey;
}


.breadcrumb-bar__title {
	font-size: 16px;
	color: $footer-green;
	margin-bottom: 0;
}

.breadcrumb-bar__bio {
	font-size: 12px;
	margin-bottom: 0;
}



.breadcrumb-bar__left {
	@include grid-column(12);
	margin-bottom: 15px;
}


.breadcrumb-bar__right {
	font-size: 12px;
	@include grid-column(12);
	color: #999;

	p {
		margin-bottom: 0;
	}

	a {
		display: inline-block;
		background-color: #999;
		transition: background-color .2s ease-in-out;
		color: white;
		padding: 1px 4px;
		border-radius: 3px;

		&:hover {
			background-color: $base-text-colour;
		}
	}
}




@media only screen and (min-width: 850px) {
	.breadcrumb-bar__left {
		@include grid-column(8);
		margin-bottom: 0;
	}

	.breadcrumb-bar__right {
		text-align: right;
		margin-top: 22px;
		@include grid-column(4);
	}
}