.slider {
	position: relative;
	margin-bottom: 25px;
	overflow: hidden;
}


.slider__slide {
	position: relative;
	width: 101%;

	.container {
		position: relative;
		height: 100%;
	}
}



.slider__slide-img {
	height: 300px;
	background: lightgrey url('replace') center center no-repeat;
	background-size: cover;
}


.slider__slide-text {
	position: absolute;
	top: 2em;
	@include grid-column(12);

	h1, h2, h3, h4 {
		font-size: 20px;
		line-height: 1.3;
		font-weight: bold;
		margin-bottom: 10px;
	}

	h1, h2, h3, h4, a, p {
		color: white;
	}

}



.slider__controls {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 150;
}


.slider__prev {
	margin-right: 5px;
}


.slider__prev,
.slider__next {
	display: inline-block;
	height: 30px;
	width: 30px;
	background-color: white;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	text-align: center;
	line-height: 35px;
	font-size: 12px;
	transition: all .2s ease-in-out;
	cursor: pointer;

	&:hover {
		background-color: $footer-green;
		color: white;
	}
}



@media only screen and (min-width: 600px) {
	.slider__prev,
	.slider__next {
		height: 40px;
		width: 40px;
		font-size: 20px;
		line-height: 45px;
	}



	.slider__slide-text {
		top: 3em;
		left: 0;
		@include grid-column(9.5);

		h1, h2, h3, h4 {
			font-size: 30px;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}

	.slider__slide-img {
		height: 350px;
	}
}
