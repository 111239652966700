.main-header {
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  padding: 20px 0;

  &.nav-down {
    .main-header__logo {
      margin: 5px 0;

      img {
        max-width: 80%;
      }
    }
  }
}

.main-header__logo {
  display: inline-block;
  transition: margin .2s ease-in-out;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;

  img {
    transition: all .2s ease-in-out;
  }
}

.main-header__nav {
  list-style: none;
  margin: 0;
  position: relative;
  display: none;

  // Main menu list items
  li {
    position: relative;
    text-transform: uppercase;
    margin-bottom: 10px;

    // Take off first one
    &:first-of-type {
      margin-left: 0;
    }

    a {
      transition: all .2s ease-in-out;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 4px;
    }

    a:hover {
      color: white;
      background-color: $footer-green;
    }

    // Sub menus, where <li> elements have a child
    ul {
      background-color: white;
      border-radius: 4px;
      border-left: 2px solid lightgrey;
      margin: 10px 0 5px 15px;
      padding-left: 5px;

      // List items in sub menus
      li {
        display: block;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &:hover, &:focus {
      // Show sub header on hover or focus
      ul {
        display: block;
      }
    }

  }
}

// Used to toggle nav when it's in mobile form
.main-header__menu-button {
  font-size: 30px;
  color: $footer-green;
  cursor: pointer;
  float: right;
  margin-top: 20px;
  transition: transform .2s ease-in-out;

  &.open {
    transform: rotate(90deg);
  }
}

// The form that appears when you click the search icon
li.main-header__search-form {
  display: block;
  margin: 5px 0 0 0;
  display: none; // Hide by default, revealed by js when click on search icon
  position: absolute;
  left: 0;
  right: 0;
  background-color: white;

  form {
    border: 4px solid #CCC;
  }

  input {
    display: inline-block;
  }

}

.main-header__search-text {
  border: 0;
  width: 90%; /* fallback if needed */
  width: calc(100% - 40px);
}

.main-header__search-input {
  width: 40px;
  height: 40px;
  border: 0;
  background-color: $footer-green;
  color: white;
  cursor: pointer;
}

.main-header__search {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  transition: color .2s ease-in-out;

  &:hover {
    color: $footer-green;
  }
}

@media only screen and (min-width: 890px) {

  .main-header__menu-button {
    display: none;
  }

  .main-header__nav {
    display: inline-block !important;

    li {
      margin-left: 5px;
      display: inline-block;

      ul {
        margin: 0;
        border-left: 0;
        display: none; // Hide by default
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        padding: 10px;

        li {
          margin: 0 2px 8px 0;
        }
      }
    }
  }

  li.main-header__search-form {
    display: none;
  }
}

@media only screen and (min-width: 720px) {

  .main-header__menu-button {
    display: none;
  }

  .main-header__nav.main-header__nav--ecs {
    display: inline-block !important;

    li {
      margin-left: 5px;
      display: inline-block;

      ul {
        margin: 0;
        border-left: 0;
        display: none; // Hide by default
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        padding: 10px;

        li {
          margin: 0 2px 8px 0;
        }
      }
    }

    li.main-header__search-form {
      display: none;
    }
  }

}