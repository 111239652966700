.up-arrow {
	height: 40px;
	width: 40px;
	border-radius: 4px;
	cursor: pointer;
	background-color: $heading-colour;
	opacity: 0;
	color: white;
	line-height: 40px;
	text-align: center;
	position: fixed;
	bottom: 1.5em;
	right: 1.5em;

	&:hover {
		background-color: $footer-green;
		opacity: 1 !important;
	}
}