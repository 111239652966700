.customers-bar__title {
  margin-bottom: 5px;
}

.customer-slideshow {
  width: 100%;
  height: 250px;

  .customer-slide {
    height: 240px;
    width: 240px;
    background-size: cover;
    display: inline-block;
    margin: 0 8px;
  }
}

.customer-logo{
  width: 200px;
  height: 100px;
}