
.two-col {
  margin-bottom: 80px;
}

.two-col__main {
  @include grid-column(12);

  h2 {
    font-weight: 400; // They're heading heirarchy is all over the place, so sorry that this is here :/
  }

  h6 {
    font-size: 13px;
    font-weight: bold;
  }
}

.two-col__aside {
  @include grid-column(12);
  margin-top: 25px;

  h5, h6 {
    font-weight: bold;
  }

  a {
    transition: color .2s ease-in-out;
    &:hover {
      color: $footer-green;
      text-decoration: underline;
    }
  }
}

@media only screen and (min-width: 720px) {
  .two-col__main {
    @include grid-column(7.5);
  }

  .two-col__aside {
    margin-top: 0;
    @include grid-column(4);
    @include grid-push(0.5);
  }
}