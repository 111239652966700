.blog-listing {
  margin-bottom: 65px;
}

.blog-listing__img {
  display: block;
  margin-bottom: 30px;
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-listing__info {
  position: relative;
}

.blog-listing__date {
  height: 45px;
  width: 45px;
  margin-bottom: 8px;
  background-color: $heading-colour;
  color: white;
  font-size: 22px;
  text-align: center;
  padding-top: 2px;

  span {
    display: block;
    font-size: 10px;
    margin-top: -10px;
  }
}

.blog-listing__summary {
  position: relative;
}

.blog-listing__title {
  a {
    color: $heading-colour;
    transition: color .2s ease-in-out;

    &:hover {
      color: $footer-green;
    }
  }
}

.blog-listing__more {
  background-color: $footer-green;
  border-radius: 4px;
  display: inline-block;
  padding: 8px 12px;
  color: white;
  opacity: .9;
  transition: opacity .2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

@media only screen and (min-width: 600px) {

  .blog-listing__date {
    position: absolute;
    margin-bottom: 0;
  }

  .blog-listing__summary {
    top: -8px;
    margin-left: 65px;
  }
}
