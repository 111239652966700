h1, h2, h3,
h4, h5, h6 {
  margin-top: 0;
  color: $heading-colour;
  font-weight: 400;
}

h1 {
  font-size: 28px;
  margin-bottom: 5px;
}

h2 {
  font-size: 23px;
}

h3 {
  font-size: 17px;
}

h4 {
  font-size: 17px;
  text-transform: uppercase;
}

h5 {
  font-size: 14px;
  text-transform: uppercase;
}

h6 {
  font-size: 14px;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

a {
  text-decoration: none;
  color: inherit;

  &:visited {
  }
}

section a {
  color: green;
}