.contact-header {
	background-color: $small-header-bg;
	border-bottom: 1px solid #f2f2f2;
	text-align: center;
}

.contact-header__nav {
	a, span {
		color: #949ba1;
		display: none;
		font-size: 11px;
		margin-bottom: 5px;
		padding: 0 10px;
		border-left: 1px solid #f2f2f2;
		vertical-align: middle;
		transition: color .15s ease-in-out;

		i {
			margin-right: 7px;
		}
	}

	a:hover {
		color: $footer-green;
	}
}










@media only screen and (min-width: 550px) {
	.contact-header {
		a, span {
		display: inline-block;
		line-height: 30px;
		margin-bottom: 0;
		font-size: 12px;
		}
	}
}

@media only screen and (min-width: 740px) {
	.contact-header {
		text-align: left;
		a, span {
		line-height: 40px;
		}
	}
}