.sidebar-list {
	margin-bottom: 3em;
	h4 {
		color: $footer-green;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 10px;
		font-size: 13px;
	}

	li {
		list-style-type: none;
		margin-bottom: 10px;
	}

	i {
		color: #999;
		opacity: .5;
		display: inline-block;
		margin-right: 7px;
	}
}
