.previous-next-bar {
	@include grid-container();



	&__prev {
		float: left;
		margin-right: 1.5em;
	}



	&__next {
		float: right;
	}



	&__prev,
	&__next {
		font-weight: bold;
		transition: color .2s ease-in-out;


		&:hover {
			color: $footer-green;
		}
	}
}