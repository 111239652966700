.pagination-bar {
  font-size: 11px;

  a, span {
    display: inline-block;
    margin-right: 9px;
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    transition: border-color .2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: $heading-colour;
    }
  }
}

.pagination-bar__current {
  background-color: $heading-colour;
  color: white;
}